import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import reedlmage from "../../images/reel_scheme.png";
import styles from "../../components/styles"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"

const style = theme => ({
  ...styles(theme),
  cableReelTable:{
    textAlign: 'center',
    '& dd':{
      // '&:nth-child(1)': {
      //   width: '14%'
      // }
    }
  },
  reelImage: {
    width: '100vw',
    maxWidth: "300px"
  }

})

const guidesCableReelTemplate = ({classes}) => (
  <Layout>
    <SEO title="Размеры, вес и объем кабельных барабанов" />
    <div>
      <span><Link to={`/guides/`}>Справочник</Link></span>
      <span> > </span>
      <span>Размеры, вес и объем кабельных барабанов</span>
    </div>
    <main>
      <h1>Размеры, вес и объем кабельных барабанов</h1>
      <p>Деревянные барабаны предназначены для намотки и&nbsp;транспортировки кабелей и&nbsp;проводов. В&nbsp;настоящее время они являются основным видом тары для кабельной продукции. Конструкция барабанов соответствует ГОСТ 5151-79. Номер барабана соответствует диаметру щеки в&nbsp;дециметрах.</p>
      <p>Деревянные барабаны изготавливают из&nbsp;обрезных досок хвойных пород по&nbsp;ГОСТ 8486-66. Барабаны &#8470;&nbsp;5&nbsp;&mdash; 18а допускается изготавливать из&nbsp;древесины мягких лиственных пород по&nbsp;ГОСТ 2695-83, кроме березы. Щеки барабанов &#8470;&nbsp;5&nbsp;&mdash; 20&nbsp;изготавливают из&nbsp;2-х слоев досок, а &#8470;&nbsp;25&nbsp;и&nbsp;выше&nbsp;&mdash; из&nbsp;3-х или 4-х слоев. Толщина досок в&nbsp;каждом слое должна быть одинаковой и&nbsp;не&nbsp;должна различаться более чем на&nbsp;40&nbsp;%. В&nbsp;осевое отверстие барабанов &#8470;&nbsp;17&nbsp;&mdash; 30&nbsp;вставляют втулки из&nbsp;чугуна или стали. Шероховатость поверхности досок щеки и&nbsp;шейки должна быть не&nbsp;более 320 мкм по&nbsp;ГОСТ 7016-82.</p>
      <img src={reedlmage} alt="Схема барабана" className={classes.reelImage}/>
      <div className={[classes.AsTable+ ' ' +classes.cableReelTable]}>
        <dl className={classes.AsTableHead}>
          <dt>Номер барабана</dt>
          <dd>Высота (D), мм</dd>
          <dd>Ширина (L), мм</dd>
          <dd>Площадь, м<sup>2</sup></dd>
          <dd>Объем, м<sup>3</sup></dd>
          <dd>Масса барабана с обшивкой, кг</dd>
          <dd>Масса барабана без обшивки, кг</dd>
        </dl>
        <dl>
          <dt>5</dt>
          <dd>500</dd>
          <dd>306</dd>
          <dd>0,15</dd>
          <dd>0,08</dd>
          <dd>15,5</dd>
          <dd>21</dd>
        </dl>
        <dl>
          <dt>6</dt>
          <dd>600</dd>
          <dd>326</dd>
          <dd>0,20</dd>
          <dd>0,12</dd>
          <dd>20,5</dd>
          <dd>27,5</dd>
        </dl>
        <dl>
          <dt>8</dt>
          <dd>800</dd>
          <dd>306</dd>
          <dd>0,24</dd>
          <dd>0,20</dd>
          <dd>43</dd>
          <dd>34</dd>
        </dl>
        <dl>
          <dt>8а</dt>
          <dd>800</dd>
          <dd>476</dd>
          <dd>0,38</dd>
          <dd>0,30</dd>
          <dd>51</dd>
          <dd>36,5</dd>
        </dl>
        <dl>
          <dt>8б</dt>
          <dd>800</dd>
          <dd>576</dd>
          <dd>0,38</dd>
          <dd>0,30</dd>
          <dd>53,5</dd>
          <dd>36,5</dd>
        </dl>
        <dl>
          <dt>10</dt>
          <dd>1000</dd>
          <dd>600</dd>
          <dd>0,60</dd>
          <dd>0,60</dd>
          <dd>56</dd>
          <dd>39</dd>
        </dl>
        <dl>
          <dt>10а</dt>
          <dd>1000</dd>
          <dd>810</dd>
          <dd>0,81</dd>
          <dd>0,81</dd>
          <dd>75</dd>
          <dd>55</dd>
        </dl>
        <dl>
          <dt>12</dt>
          <dd>1220</dd>
          <dd>600</dd>
          <dd>0,73</dd>
          <dd>0,89</dd>
          <dd>132</dd>
          <dd>99</dd>
        </dl>
        <dl>
          <dt>12а</dt>
          <dd>1220</dd>
          <dd>810</dd>
          <dd>0,99</dd>
          <dd>1,21</dd>
          <dd>151</dd>
          <dd>107</dd>
        </dl>
        <dl>
          <dt>12б</dt>
          <dd>1220</dd>
          <dd>700</dd>
          <dd>0,85</dd>
          <dd>1,04</dd>
          <dd>145</dd>
          <dd>110</dd>
        </dl>
        <dl>
          <dt>14</dt>
          <dd>1400</dd>
          <dd>826</dd>
          <dd>1,16</dd>
          <dd>1,62</dd>
          <dd>217</dd>
          <dd>165</dd>
        </dl>
        <dl>
          <dt>14а</dt>
          <dd>1400</dd>
          <dd>616</dd>
          <dd>0,96</dd>
          <dd>1,21</dd>
          <dd>200</dd>
          <dd>152</dd>
        </dl>
        <dl>
          <dt>14б</dt>
          <dd>1400</dd>
          <dd>716</dd>
          <dd>1,00</dd>
          <dd>1,40</dd>
          <dd>234</dd>
          <dd>186</dd>
        </dl>
        <dl>
          <dt>14в</dt>
          <dd>1400</dd>
          <dd>850</dd>
          <dd>1,19</dd>
          <dd>1,67</dd>
          <dd>226</dd>
          <dd>172</dd>
        </dl>
        <dl>
          <dt>14г</dt>
          <dd>1400</dd>
          <dd>1016</dd>
          <dd>1,42</dd>
          <dd>1,99</dd>
          <dd>266</dd>
          <dd>202</dd>
        </dl>
        <dl>
          <dt>16</dt>
          <dd>1600</dd>
          <dd>716</dd>
          <dd>1,15</dd>
          <dd>1,83</dd>
          <dd>308</dd>
          <dd>241</dd>
        </dl>
        <dl>
          <dt>16а</dt>
          <dd>1600</dd>
          <dd>916</dd>
          <dd>1,47</dd>
          <dd>2,34</dd>
          <dd>323</dd>
          <dd>237</dd>
        </dl>
        <dl>
          <dt>17</dt>
          <dd>1700</dd>
          <dd>890</dd>
          <dd>1,51</dd>
          <dd>2,57</dd>
          <dd>367</dd>
          <dd>277</dd>
        </dl>
        <dl>
          <dt>17а</dt>
          <dd>1700</dd>
          <dd>1040</dd>
          <dd>1,77</dd>
          <dd>3,00</dd>
          <dd>390</dd>
          <dd>295</dd>
        </dl>
        {/*<dl>*/}
        {/*  <dt>17г-01</dt>*/}
        {/*  <dd>1744</dd>*/}
        {/*  <dd>920</dd>*/}
        {/*  <dd>1,60</dd>*/}
        {/*  <dd>2,80</dd>*/}
        {/*  <dd>319</dd>*/}
        {/*  <dd>229</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>17д-01</dt>*/}
        {/*  <dd>1744</dd>*/}
        {/*  <dd>1070</dd>*/}
        {/*  <dd>1,87</dd>*/}
        {/*  <dd>3,25</dd>*/}
        {/*  <dd>342</dd>*/}
        {/*  <dd>247</dd>*/}
        {/*</dl>*/}
        <dl>
          <dt>18</dt>
          <dd>1800</dd>
          <dd>1060</dd>
          <dd>1,91</dd>
          <dd>3,43</dd>
          <dd>535</dd>
          <dd>422</dd>
        </dl>
        {/*<dl>*/}
        {/*  <dt>18а</dt>*/}
        {/*  <dd>1800</dd>*/}
        {/*  <dd>1260</dd>*/}
        {/*  <dd>2,27</dd>*/}
        {/*  <dd>4,08</dd>*/}
        {/*  <dd>606</dd>*/}
        {/*  <dd>422</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18б</dt>*/}
        {/*  <dd>1800</dd>*/}
        {/*  <dd>1222</dd>*/}
        {/*  <dd>2,28</dd>*/}
        {/*  <dd>4,25</dd>*/}
        {/*  <dd>594</dd>*/}
        {/*  <dd>470</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18в</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>950</dd>*/}
        {/*  <dd>1,77</dd>*/}
        {/*  <dd>3,30</dd>*/}
        {/*  <dd>434</dd>*/}
        {/*  <dd>342</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18г</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1130</dd>*/}
        {/*  <dd>2,11</dd>*/}
        {/*  <dd>3,93</dd>*/}
        {/*  <dd>540</dd>*/}
        {/*  <dd>427</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18д</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1230</dd>*/}
        {/*  <dd>2,29</dd>*/}
        {/*  <dd>4,27</dd>*/}
        {/*  <dd>494</dd>*/}
        {/*  <dd>370</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18е</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1230</dd>*/}
        {/*  <dd>2,29</dd>*/}
        {/*  <dd>4,27</dd>*/}
        {/*  <dd>504</dd>*/}
        {/*  <dd>380</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18ж</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1230</dd>*/}
        {/*  <dd>2,29</dd>*/}
        {/*  <dd>4,27</dd>*/}
        {/*  <dd>524</dd>*/}
        {/*  <dd>400</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18з</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1230</dd>*/}
        {/*  <dd>2,29</dd>*/}
        {/*  <dd>4,27</dd>*/}
        {/*  <dd>554</dd>*/}
        {/*  <dd>430</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18к-01</dt>*/}
        {/*  <dd>1846</dd>*/}
        {/*  <dd>1090</dd>*/}
        {/*  <dd>2,01</dd>*/}
        {/*  <dd>3,71</dd>*/}
        {/*  <dd>474</dd>*/}
        {/*  <dd>361</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18л-01</dt>*/}
        {/*  <dd>1846</dd>*/}
        {/*  <dd>1090</dd>*/}
        {/*  <dd>2,01</dd>*/}
        {/*  <dd>3,71</dd>*/}
        {/*  <dd>474</dd>*/}
        {/*  <dd>361</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18к-01</dt>*/}
        {/*  <dd>1846</dd>*/}
        {/*  <dd>1090</dd>*/}
        {/*  <dd>2,01</dd>*/}
        {/*  <dd>3,71</dd>*/}
        {/*  <dd>479</dd>*/}
        {/*  <dd>366</dd>*/}
        {/*</dl>*/}
        <dl>
          <dt>20</dt>
          <dd>2000</dd>
          <dd>1180</dd>
          <dd>2,36</dd>
          <dd>4,72</dd>
          <dd>763</dd>
          <dd>584</dd>
        </dl>
        {/*<dl>*/}
        {/*  <dt>20а</dt>*/}
        {/*  <dd>2080</dd>*/}
        {/*  <dd>1302</dd>*/}
        {/*  <dd>2,71</dd>*/}
        {/*  <dd>5,63</dd>*/}
        {/*  <dd>725</dd>*/}
        {/*  <dd>555</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>20б</dt>*/}
        {/*  <dd>2080</dd>*/}
        {/*  <dd>1242</dd>*/}
        {/*  <dd>2,58</dd>*/}
        {/*  <dd>5,37</dd>*/}
        {/*  <dd>941</dd>*/}
        {/*  <dd>720</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>20в</dt>*/}
        {/*  <dd>2080</dd>*/}
        {/*  <dd>1150</dd>*/}
        {/*  <dd>2,39</dd>*/}
        {/*  <dd>4,98</dd>*/}
        {/*  <dd>700</dd>*/}
        {/*  <dd>560</dd>*/}
        {/*</dl>*/}
        <dl>
          <dt>22</dt>
          <dd>2200</dd>
          <dd>1236</dd>
          <dd>2,72</dd>
          <dd>5,98</dd>
          <dd>985</dd>
          <dd>759</dd>
        </dl>
        {/*<dl>*/}
        {/*  <dt>22а</dt>*/}
        {/*  <dd>2280</dd>*/}
        {/*  <dd>1348</dd>*/}
        {/*  <dd>3,07</dd>*/}
        {/*  <dd>7,01</dd>*/}
        {/*  <dd>1029</dd>*/}
        {/*  <dd>763</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>22б</dt>*/}
        {/*  <dd>2280</dd>*/}
        {/*  <dd>1398</dd>*/}
        {/*  <dd>3,19</dd>*/}
        {/*  <dd>7,27</dd>*/}
        {/*  <dd>1110</dd>*/}
        {/*  <dd>833</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>17в с брус</dt>*/}
        {/*  <dd>1764</dd>*/}
        {/*  <dd>944</dd>*/}
        {/*  <dd>1,67</dd>*/}
        {/*  <dd>2,94</dd>*/}
        {/*  <dd>380</dd>*/}
        {/*  <dd>290</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>18и с брус</dt>*/}
        {/*  <dd>1864</dd>*/}
        {/*  <dd>1122</dd>*/}
        {/*  <dd>2,09</dd>*/}
        {/*  <dd>3,90</dd>*/}
        {/*  <dd>619</dd>*/}
        {/*  <dd>435</dd>*/}
        {/*</dl>*/}
        {/*<dl>*/}
        {/*  <dt>20в с брус</dt>*/}
        {/*  <dd>2080</dd>*/}
        {/*  <dd>1250</dd>*/}
        {/*  <dd>2,60</dd>*/}
        {/*  <dd>5,41</dd>*/}
        {/*  <dd>779</dd>*/}
        {/*  <dd>600</dd>*/}
        {/*</dl>*/}
      </div>
    </main>
  </Layout>
)

guidesCableReelTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(guidesCableReelTemplate)

